module controllers {
    export module manifest {
        interface IManifestScope extends ng.IScope {
            IsLoading: boolean;
            ShowGrid: boolean;
        }

        interface IManifestAction {
            Name: string;
            callBack(): void;
        }

        enum EnumManifestRights {
            RequestClearing = 478,
            SendEDI = 476,
            ReadyForDispatch = 551,
            Dispatch = 552,
            ValidateAllEDI = 566,
            GenerateAllGoodsDeclarations = 567,
        }
        
        export class manifestListCtrl {
            static $inject = ["$scope", 'manifestService', '$rootScope', 'generalService', '$q', 'entityService', '$state', 'classificationValueService', '$timeout',
                '$transitions', '$uibModal', "bsLoadingOverlayService", "$anchorScroll", "$interval", 'userAccountService',"manifestTypeService"];


            manifestSearch: interfaces.manifest.IManifestSearch = {
                ManifestStatusId: 0,
                OwnerEntityId: 0
            };

            searchAccordian: boolean = false;

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            dropdownsObject = {
                Entities: Array<interfaces.applicationcore.IEntity>()
            };

            statusList: interfaces.applicationcore.IDropdownModel[] = [];

            apiManifestList: uiGrid.IGridApi;
            selectedManifest: uiGrid.IGridRow;

            actionsList: Array<IManifestAction>;
            requestClearingAction: IManifestAction;
            cIAcknowledgedAction: IManifestAction;
            readyForDispatchAction: IManifestAction;
            dispatchAction: IManifestAction;
            arrivedAction: IManifestAction;
            deliveredAction: IManifestAction;
            closedAction: IManifestAction;
            cancelAction: IManifestAction;
            generateAllGoodsDeclarationActions: IManifestAction;
            validateAllEDIAction: IManifestAction;
            sendAllEDIAction: IManifestAction;
            externalClearingAction: IManifestAction;

            actionRightsList: number[] = [];
            createNewRight: boolean = false;

            myHook: any;

            filterNames: string[] = ["OwnerEntityCode", "ManifestNumber", "ManifestDate",
                "AlternateReference", "ManifestType", "Status",
                "Route", "LoadingDate", "MasterCargoCarrier", "ResponsibleUser"];
            filterList: interfaces.applicationcore.IKeyValue[] = [];

            sortName: string;
            sortDirection: string;

            constructor(private $scope: IManifestScope, private manifestService: interfaces.manifest.IManifestService,
                private $rootScope: interfaces.applicationcore.IRootScope, public generalService: interfaces.applicationcore.IGeneralService, private $q: ng.IQService,
                private entityService: interfaces.applicationcore.IEntityService, private $state: ng.ui.IStateService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private $timeout: ng.ITimeoutService,
                private $transitions: ng.ui.core.ITransition,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $interval: ng.IIntervalService,
                private userAccountService: interfaces.applicationcore.IUserAccountService,
                private manifestTypeService: interfaces.manifest.IManifestTypeService,) {

                this.loadControls();

                this.myHook = $transitions.onSuccess({
                    to: 'auth.Manifests',
                    from: 'auth.Manifests.**'
                }, () => {
                    this.search_click();
                });

                $scope.$on('$destroy', () => {
                    $transitions.onStart({}, this.myHook)
                });
            }


            loadControls() {
                this.$scope.ShowGrid = false;

                let controlPromises = [
                    this.loadEntities(),
                    this.loadStatuses(),
                    this.loadActionRights(),
                    this.loadCreateNewRight()
                ]

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'man.list'
                }, () => {
                    return this.$q.all(controlPromises).then((data) => {
                        this.$scope.ShowGrid = true;
                        this.DoSearch();
                    });
                });

                this.createActions();
            }

            loadEntities(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                this.entityService.getList().query((successList) => {
                    this.dropdownsObject.Entities = successList;
                    deferred.resolve(true);
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            loadStatuses() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.classificationValueService.GetClassificationValueDropdownByClassification().query({ classificationId: 56 }, (result) => {
                    this.statusList = result;
                    defered.resolve(this.statusList);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            loadActionRights() {
                return this.manifestService.getManifestRights().query((result) => {
                    this.actionRightsList = result;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            loadCreateNewRight() {
                this.createNewRight = false;
                return this.userAccountService.hasRight(686).get((result) => {
                    if (result) {
                        this.createNewRight = true;
                    }
                }).$promise;
            }

            search_click() {
                this.$scope.ShowGrid = true;
                this.$scope.IsLoading = true;
                this.searchAccordian = false;
                this.DoSearch();
            }

            showAll_click() {
                this.$scope.ShowGrid = true;
                this.$scope.IsLoading = true;
                this.searchAccordian = false;

                this.manifestSearch = {
                    ManifestStatusId: -1,
                    OwnerEntityId: 0
                }

                if (this.apiManifestList !== undefined) {
                    this.apiManifestList.grid.clearAllFilters(true, true, true).then(() => {
                        this.apiManifestList.grid.resetColumnSorting(null);
                        this.DoSearch();
                    });
                } else {
                    this.DoSearch();
                }
            }

            downloadToExcel() {
                this.$scope.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.manifestSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiManifestList) {

                    var grid = this.apiManifestList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }

                var params = {
                    connectSearch: searchObject
                }

                this.manifestService.getManifestListExcel(params).then(() => {
                    this.$scope.IsLoading = false;
                }, (data) => {
                    this.$scope.IsLoading = false;
                    this.gvwManifestList.data = [];
                    this.gvwManifestList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (this.apiManifestList.grid.getColumn(name).filters[0].term || this.apiManifestList.grid.getColumn(name).filters[0].term == "") {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiManifestList.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiManifestList.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.apiManifestList.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    var column = this.apiManifestList.grid.getColumn(this.sortName);

                    if (column.sort.direction != this.sortDirection) {
                        this.apiManifestList.grid.sortColumn(column, this.sortDirection);
                    }

                    this.apiManifestList.grid.getColumn(this.sortName).sort.direction = this.sortDirection;
                }
            }

            gvwManifestList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => {
                    this.registerGridApi(gridApi);
                },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                }, {
                        name: "EDIT",
                        displayName: "",
                        field: "Id",
                        enableFiltering: false,
                        cellTemplate: `
                            <div> 
                                <button type="button" ui-sref="auth.Manifests.Update({ manId: MODEL_COL_FIELD })" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        width: 35
                    }, {
                        name: "EDIStatus",
                        displayName: "EDI",
                        field: "EDIStatus",
                        enableFiltering: false,
                        cellTemplate: `
                            <div class="GridButton">
                                <center>
                                    <div ng-if="row.entity.EDIStatusIndicator === 0">
                                        <button type="button" ng-click="grid.appScope.manifestListCtrl.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                            <span class="fa fa-edit"></span>
                                        </button>
                                    </div>
                                    <div ng-if="row.entity.EDIStatusIndicator === 1">
                                        <button type="button" ng-click="grid.appScope.manifestListCtrl.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                            <span class="fa fa-times" style="color: #cb2330;"></span>
                                        </button>
                                    </div>
                                    <div ng-if="row.entity.EDIStatusIndicator === 2">
                                        <button type="button" ng-click="grid.appScope.manifestListCtrl.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                            <span class="fa fa-exclamation-triangle" style="color: #f57f20; opacity: 0.7;"></span>
                                        </button>
                                    </div>
                                    <div ng-if="row.entity.EDIStatusIndicator === 3">
                                        <button type="button" ng-click="grid.appScope.manifestListCtrl.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                            <span class="fa fa-check-circle" style="color: #bcd856;"></span>
                                        </button>
                                    </div>
                                </center>
                            </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        width: 45
                    }, {
                        name: "OwnerEntityCode",
                        displayName: "Entity",
                        field: "OwnerEntityCode",
                        width: 60,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    }, {
                        name: "ManifestNumber",
                        displayName: "Manifest",
                        field: "ManifestNumber",
                        width: 140,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }, {
                        name: "ManifestDate",
                        displayName: "Manifest Date",
                        field: "ManifestDate",
                        width: 100,
                        type: "date",
                        cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="orderDate"></gts-date>
                                        
                            </div>`,
                    }, {
                        name: "AlternateReference",
                        displayName: "Alternate Reference",
                        field: "AlternateReference",
                        width: 135,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    },  {
                        name: "Status",
                        displayName: "Status",
                        field: "Status",
                        width: 130,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    },
                    {
                        name: "Route",
                        displayName: "Route",
                        field: "Route",
                        width: 200,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    }, {
                        name: "LoadingDate",
                        displayName: "Loading Date",
                        field: "LoadingDate",
                        width: 101,
                        type: "date",
                        cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="orderDate"></gts-date>
                                        
                            </div>`,
                    },
                    {
                        name: "MasterCargoCarrier",
                        displayName: "Cargo Carrier",
                        field: "MasterCargoCarrier",
                        width: 200,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    },
                    {
                        name: "TransportMode",
                        displayName: "Mode",
                        field: "TransportMode",
                        width: 100,
                        cellTemplate: '<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    },
                    {
                        name: "TransportReference",
                        displayName: "Transport Details",
                        field: "TransportReference",
                        width: 130,
                        enableFiltering: false,
                        enableSorting: false,
                        enableColumnMenu: false,
                        cellTemplate: '<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>',
                    },
                    {
                        name: "CountryLoadingCode",
                        displayName: "Loading",
                        field: "CountryLoadingCode",
                        width: 100,
                        cellTemplate: '<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    },
                    {
                        name: "CountryDestinationCode",
                        displayName: "Destination",
                        field: "CountryDestinationCode",
                        width: 100,
                        cellTemplate: '<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    },
                    {
                        name: "ResponsibleUser",
                        displayName: "Responsible User",
                        field: "ResponsibleUser",
                        width: 125,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    },
                    {
                        name: "ManifestType",
                        displayName: "Type",
                        field: "ManifestType",
                        width: 105,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }
                ]
            };

            openEDIPopup(manId: number)
            {
                //Open Popup.
                return this.$uibModal.open({
                    animation: true,
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">Manifest EDI Status</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <div class="vertical-align">
                                        <div class="col-md-12">
                                            <div id="gridTest" ui-grid="gvwManifestCustomsSummary" ui-grid-resize-columns ui-grid-cellNav class="gridSmallScreen"
                                                    ng-if="gvwManifestCustomsSummary">
                                                    <div layout="column" layout-align="center center" class="grid-msg-overlay" ng-if="IsLoading">
                                                        <div class="loadingGrid" ng-show="IsLoading">
                                                            <i class="fa fa-spinner fa-spin"></i>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                                </div>
                            </div>`,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, manifestService: interfaces.manifest.IManifestService) => {
                        
                        $scope.ok = () => {
                            $uibModalInstance.dismiss(false);
                        };
                        
                        $scope.gvwManifestCustomsSummary = {
                            data: [],
                            enableFiltering: false,
                            useExternalFiltering: false,
                            enableCellEdit: false,
                            useExternalSorting: false,
                            multiSelect: false,
                            enableColumnResizing: true,
                            enableFullRowSelection: false,
                            useExternalPagination: true,
                            enableRowHeaderSelection: false,
                            enableHorizontalScrollbar: 2,
                            enableVerticalScrollbar: 1,
                            onRegisterApi: (gridApi) => {
                                $scope.IsLoading = true;

                                manifestService.GetManifestCustomsSummary(manId).query((result: interfaces.manifest.IManifestCustomsSummary) => {
                                    $scope.gvwManifestCustomsSummary.data = result;
                                    $scope.IsLoading = false;
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    $scope.IsLoading = false;
                                });
                            },
                            columnDefs: [{
                                name: "TYPE",
                                displayName: "Message Type",
                                field: "Type",
                                type: "string",
                                width: 105,
                            }, {
                                name: "Reference",
                                displayName: "Reference",
                                field: "Reference",
                                type: "string",
                                width: 85,
                            }, {
                                name: "DeclarationCountry",
                                displayName: "Declaration Country",
                                field: "DeclarationCountry",
                                type: "string",
                                width: 135,
                            }, {
                                name: "CPC",
                                displayName: "CPC",
                                field: "CPC",
                                type: "string",
                                width: 75,
                            }, {
                                name: "DocumentNumber",
                                displayName: "Document Number",
                                field: "DocumentNumber",
                                type: "string",
                                width: 145,
                            }, {
                                name: "CustomsStatus",
                                displayName: "Customs Status",
                                field: "CustomsStatus",
                                type: "string",
                                width: 132,
                            }, {
                                name: "CustomsStatusDate",
                                displayName: "Customs Status Date",
                                field: "CustomsStatusDate",
                                type: "string",
                                cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                                width: 147,
                            }, {
                                name: "CustomsMessage",
                                displayName: "Customs Message",
                                field: "CustomsMessage",
                                type: "string",
                                width: 135,
                                cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                            }, {
                                name: "Version",
                                displayName: "Version",
                                field: "Version",
                                    width: 65,
                            }, {
                                name: "Function",
                                displayName: "Function",
                                field: "Function",
                                type: "string",
                                width: 75,
                            }, {
                                name: "AssessmentDate",
                                displayName: "Assessment Date",
                                field: "AssessmentDate",
                                type: "string",
                                width: 135,
                                cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD HH:mm:ss"}}</div>`,
                            }, ]
                        };
                    },
                    size: "xlg",
                    resolve: {
                    }
                }).result;
            }

            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiManifestList = gridApi;

                this.apiManifestList.cellNav.on.navigate(this.$scope, (newCol) => {
                    this.$timeout(() => {
                        this.apiManifestList.selection.selectRow(newCol.row.entity);
                    });
                });

                this.apiManifestList.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    this.$timeout(() => {
                        this.loadActions(selected.entity.StatusClassificationValueId);
                        this.selectedManifest = selected;
                    });
                });

                this.apiManifestList.core.on.filterChanged(this.$scope, () => { this.ManifestListFilterChange(gridApi) });
                this.apiManifestList.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.ManifestListShortChange(gridApi);

                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });
                this.apiManifestList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ManifestListPageChange(newPage, pageSize) });
            }

            ManifestListPageChange(newPage, pageSize) {
                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            ManifestListShortChange(gridApi) {
                this.DoSearch();
            }

            ManifestListFilterChange(gridApi: uiGrid.IGridApi) {
                _.forEach(gridApi.grid.columns, (c) => {
                    if(!c.filters[0].term) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', c.field));

                        if(keyValue) {
                            this.filterList.splice(this.filterList.indexOf(keyValue), 1);
                        }
                    }
                });
                
                this.DoSearch();
            }

            DoSearch() {
                this.$scope.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }

                angular.forEach(this.manifestSearch, (n, key) => {
                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiManifestList) {
                    var grid = this.apiManifestList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }

                var params = {
                    connectSearch: searchObject,
                    numberRecords: this.paginationOptions.pageSize,
                    pageNumber: this.paginationOptions.pageNumber
                }

                this.manifestService.getManifestList().query(params, (data) => {
                    this.$scope.IsLoading = false;
                    this.gvwManifestList.data = data;

                    if (data[0]) {
                        this.gvwManifestList.totalItems = data[0].NumRecs;
                    } else {
                        this.gvwManifestList.totalItems = 0;
                    }
                    this.$timeout(() => {
                        if (this.selectedManifest) {
                            var index = _.findIndex(this.gvwManifestList.data, (o) => { return o.Id === this.selectedManifest.entity.Id });
                            this.apiManifestList.selection.selectRow(this.gvwManifestList.data[index]);
                        }
                        this.getFilters();
                        this.setFilters();
                    });
                }, (data) => {
                    this.$scope.IsLoading = false;
                    this.gvwManifestList.data = [];
                    this.gvwManifestList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            printDocuments() {
                if (this.apiManifestList && this.apiManifestList.selection.getSelectedRows().length > 0) {
                    var selected = this.apiManifestList.selection.getSelectedRows()[0];

                    this.$state.go("auth.Manifests.Update.Documents", { manId: selected.Id });
                } else {
                    this.generalService.displayMessage("Please select a Manifest", Enum.EnumMessageType.Warning);
                }
            }

            checkConsignmentSelected() {
                if (!this.apiManifestList || this.apiManifestList.selection.getSelectedRows().length < 1) {
                    this.generalService.displayMessage("Please select a Manifest", Enum.EnumMessageType.Warning);
                }
            }

            showRequestClearingModal(manifest: interfaces.manifest.IManifestDisplay) {

                return this.$uibModal.open({
                    animation: true,
                    size: 'sm',
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">Manifest {{manifestNumber}}</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <p><b>Request clearing for Manifest {{manifestNumber}}</b></p>
                                    <br />
                                    <p>
                                        Have you checked that all informations on the manifest is correct and that 
                                        the correct consignments have been linked to the manifest?
                                    </p>
                                    <br />
                                    <div class="row vertical-align">
                                        <div class="col-md-1">
                                            <p class="input-group-sm">
                                                <input type="checkbox" ng-model="confirm">
                                            </p>
                                        </div>
                                        <div class="col-md-11">
                                            <label>Yes I have checked the information is correct.</label>
                                        </div>
                                    </div>
                                    <div class="row vertical-align">
                                        <div class="col-md-1">
                                            <p class="input-group-sm">
                                                <input type="checkbox" ng-model="automateClearing" ng-disabled="!automateClearing_Original">
                                            </p>
                                        </div>
                                        <div class="col-md-11">
                                            <label>Automate Customs Clearing? (If ticked the automated clearing process will begin)</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()" ng-disabled="!confirm">OK</button>
                                    <button class="btn btn-info" type="button" ng-click="cancel()">Cancel</button>
                                </div>
                            </div>`,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, manifestNumber: string, automateClearing: boolean) => {
                        $scope.manifestNumber = manifestNumber;
                        $scope.automateClearing = automateClearing;
                        $scope.automateClearing_Original = automateClearing; //if route is not setup to allow automated clearing, the user must not be able to change

                        $scope.ok = () => {
                            manifest.AutomateClearing = $scope.automateClearing;
                            $uibModalInstance.close(manifest);
                        };

                        $scope.cancel = () => {
                            $uibModalInstance.dismiss(false);
                        };
                    },
                    resolve: {
                        manifestNumber: () => { return manifest.ManifestNumber; },
                        automateClearing: () => { return manifest.AutomateClearing; }
                    }
                }).result;
            }

            requestClearing() {
                var selected = this.apiManifestList.selection.getSelectedRows()[0];

                //Data response is IManifestDisplay
                this.showRequestClearingModal(selected).then((data) => {
                    if (data) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'man.list'
                        }, () => {
                                
                                return this.manifestService.requestClearing(selected.Id, data.AutomateClearing).save((result) => {
                                    this.$anchorScroll("topAnchor");
                                    this.generalService.displayMessageHandler(result);

                                    if (!result.HasErrorMessage) {
                                        this.DoSearch();
                                }
                            }, (errorResponse) => {
                                this.$anchorScroll("topAnchor");
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                    }

                }, () => {

                });
            }

            cIAcknowledged() {
                var selected = this.apiManifestList.selection.getSelectedRows()[0];
                this.generalService.displayConfirmationBox(selected.ManifestNumber, "Are you sure?").then((result) => {
                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'man.list'
                        }, () => {
                            return this.manifestService.cIAcknowledged(selected.Id).save((result) => {
                                this.$anchorScroll("topAnchor");
                                this.generalService.displayMessageHandler(result);

                                if (!result.HasErrorMessage) {
                                    this.DoSearch();
                                }
                            }, (errorResponse) => {
                                this.$anchorScroll("topAnchor");
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                    }
                }, () => { });
            }

            showReadyForDispatchModal(manifest: interfaces.manifest.IManifestDisplay) {
                return this.$uibModal.open({
                    animation: true,
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">Ready For Dispatch</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <div class="row">
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt></opt>
                                                <label>Manifest Number</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{manifestNumber}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt></opt>
                                                <label>Manifest Date</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{manifestDate | momentDateFilter: 'YYYY/MM/DD HH:mm'}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt></opt>
                                                <label>Alternate Code</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{alternateReference}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt></opt>
                                                <label>Route</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{route}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt></opt>
                                                <label>Transport Mode</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{transportMode}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <req></req>
                                                <label>Actual Date Of Clearing</label>
                                            </div>
                                            <div class="col-md-6">
                                                <gts-date format="yyyy/MM/dd" ng-model="clearanceDate" name="clearance"></gts-date>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()" ng-disabled="!clearanceDate">OK</button>
                                    <button class="btn btn-info" type="button" ng-click="cancel()">Cancel</button>
                                </div>
                            </div>`,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService) => {
                        $scope.manifestNumber = manifest.ManifestNumber;
                        $scope.manifestDate = manifest.ManifestDate;
                        $scope.alternateReference = manifest.AlternateReference;
                        $scope.route = manifest.Route;
                        $scope.transportMode = manifest.TransportMode;
                        $scope.clearanceDate = manifest.ClearanceDate;

                        $scope.ok = () => {
                            manifest.ClearanceDate = $scope.clearanceDate;
                            $uibModalInstance.close(true);
                        };

                        $scope.cancel = () => {
                            $uibModalInstance.dismiss(false);
                        };
                    },
                    resolve: {
                    }
                }).result;
            }

            readyForDispatch() {
                var selected = this.apiManifestList.selection.getSelectedRows()[0];

                this.showReadyForDispatchModal(selected).then((data) => {
                    if (data && selected.ClearanceDate && selected.ClearanceDate.isValid()) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'man.list'
                        }, () => {
                            return this.manifestService.updateManifestReadyforDispatch(selected.Id).save({}, selected.ClearanceDate, (result) => {
                                this.$anchorScroll("topAnchor");
                                this.generalService.displayMessageHandler(result);

                                if (!result.HasErrorMessage) {
                                    this.DoSearch();
                                }
                            }, (errorResponse) => {
                                this.$anchorScroll("topAnchor");
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                    }
                }, () => {

                });
            }
            
            showDispatchModal(manifest: interfaces.manifest.IManifestDisplay) {
                return this.$uibModal.open({
                    animation: true,
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">Dispatch</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <div class="row">
                                        <div class="vertical-align">
                                            <div class="col-md-5">
                                                <opt></opt>
                                                <label>Manifest Number</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{manifestNumber}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-5">
                                                <opt></opt>
                                                <label>Manifest Date</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{manifestDate | momentDateFilter: 'YYYY/MM/DD HH:mm'}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-5">
                                                <opt></opt>
                                                <label>Alternate Code</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{alternateReference}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-5">
                                                <opt></opt>
                                                <label>Route</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{route}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-5">
                                                <opt></opt>
                                                <label>Transport Mode</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{transportMode}}
                                                </p>
                                            </div>
                                        </div>
                                        <br />
                                        <div class="vertical-align" ng-if="cargoTypeId === 4">
                                            <div class="col-md-12">
                                                <div id="gridTest" ui-grid="gvwContainers" ui-grid-resize-columns ui-grid-cellNav class="gridSmallSmall"
                                                     ng-if="gvwContainers">
                                                    <div layout="column" layout-align="center center" class="grid-msg-overlay" ng-if="IsLoading">
                                                        <div class="loadingGrid" ng-show="IsLoading">
                                                            <i class="fa fa-spinner fa-spin"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div class="vertical-align">
                                            <div class="col-md-5">
                                                <req></req>
                                                <label>Actual Loading Date and Time</label>
                                            </div>
                                            <div class="col-md-6">
                                                <gts-date format="yyyy/MM/dd HH:mm" ng-model="loadingDate" name="loading" show-time="true"></gts-date>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()" ng-disabled="!loadingDate">OK</button>
                                    <button class="btn btn-info" type="button" ng-click="cancel()">Cancel</button>
                                </div>
                            </div>`,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, containerService: interfaces.logistics.IContainerService) => {
                        $scope.manifestNumber = manifest.ManifestNumber;
                        $scope.manifestDate = manifest.ManifestDate;
                        $scope.alternateReference = manifest.AlternateReference;
                        $scope.route = manifest.Route;
                        $scope.transportMode = manifest.TransportMode;
                        $scope.loadingDate = manifest.LoadingDate;
                        $scope.cargoTypeId = manifest.CargoTypeId;

                        $scope.ok = () => {
                            manifest.LoadingDate = $scope.loadingDate;
                            $uibModalInstance.close(true);
                        };

                        $scope.cancel = () => {
                            $uibModalInstance.dismiss(false);
                        };

                        $scope.gvwContainers = {
                            data: [],
                            enableFiltering: false,
                            useExternalFiltering: false,
                            enableCellEdit: false,
                            useExternalSorting: false,
                            multiSelect: false,
                            enableColumnResizing: true,
                            enableFullRowSelection: false,
                            useExternalPagination: true,
                            enableRowHeaderSelection: false,
                            enableHorizontalScrollbar: 2,
                            enableVerticalScrollbar: 1,
                            onRegisterApi: (gridApi) => {
                                $scope.IsLoading = true;

                                containerService.getManifestContainers(manifest.Id).query((result) => {
                                    $scope.gvwContainers.data = result;
                                    $scope.IsLoading = false;
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    $scope.IsLoading = false;
                                });
                            },
                            columnDefs: [{
                                name: "CONTAINERNUMBER",
                                displayName: "Container Number",
                                field: "Number",
                                type: "string",
                            }, {
                                name: "CONTAINERISO",
                                displayName: "Container Size",
                                field: "ContainerISOCode",
                                type: "string",
                                cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                            }, {
                                name: "GROSSMASS",
                                displayName: "Gross Mass",
                                field: "GrossMass",
                                type: "number",
                                cellClass: 'text-right',
                                cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`
                            }]
                        };
                    },
                    resolve: {
                    }
                }).result;
            }

            dispatch() {
                var selected = this.apiManifestList.selection.getSelectedRows()[0];

                this.showDispatchModal(selected).then((data) => {
                    if (data && selected.LoadingDate && selected.LoadingDate.isValid()) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'man.list'
                        }, () => {
                            return this.manifestService.updateManifestDispatched(selected.Id).save({}, selected.LoadingDate, (result) => {
                                this.$anchorScroll("topAnchor");
                                this.generalService.displayMessageHandler(result);

                                if (!result.HasErrorMessage) {
                                    this.DoSearch();
                                }
                            }, (errorResponse) => {
                                this.$anchorScroll("topAnchor");
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                    }
                }, () => {

                });
            }
            
            showArrivedModal(manifest: interfaces.manifest.IManifestDisplay) {

                return this.$uibModal.open({
                    animation: true,
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">Manifest {{manifestNumber}}</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <div class="row">
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <req></req>
                                                <label>Actual Loading Date</label>
                                            </div>
                                            <div class="col-md-6">
                                                <gts-date format="yyyy/MM/dd HH:mm" ng-model="loadingDate" name="loadingDate" show-time="true"></gts-date>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <req></req>
                                                <label>Arrival Date</label>
                                            </div>
                                            <div class="col-md-6">
                                                <gts-date format="yyyy/MM/dd" ng-model="arrivalDate" name="arrival"></gts-date>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()" ng-disabled="!loadingDate || !arrivalDate">OK</button>
                                    <button class="btn btn-info" type="button" ng-click="cancel()">Cancel</button>
                                </div>
                            </div>`,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService) => {
                        $scope.manifestNumber = manifest.ManifestNumber;
                        $scope.loadingDate = manifest.LoadingDate;
                        $scope.arrivalDate = manifest.ArrivalDate;

                        $scope.ok = () => {
                            manifest.LoadingDate = $scope.loadingDate;
                            manifest.ArrivalDate = $scope.arrivalDate;
                            $uibModalInstance.close(true);
                        };

                        $scope.cancel = () => {
                            $uibModalInstance.dismiss(false);
                        };
                    },
                    resolve: {
                    }
                }).result;
            }

            arrived() {
                var selected = this.apiManifestList.selection.getSelectedRows()[0];

                this.showArrivedModal(selected).then((data) => {
                    if (data) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'man.list'
                        }, () => {
                            return this.manifestService.updateManifestArrived(selected.Id).save({}, {
                                dateLoading: selected.LoadingDate,
                                dateArrived: selected.ArrivalDate
                            }, (result) => {
                                this.$anchorScroll("topAnchor");
                                this.generalService.displayMessageHandler(result);

                                if (!result.HasErrorMessage) {
                                    this.DoSearch();
                                }
                                }, (errorResponse) => {
                                    this.$anchorScroll("topAnchor");
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                    }

                }, () => {

                });
            }

            showDeliveredModal(manifest: interfaces.manifest.IManifestDisplay) {

                return this.$uibModal.open({
                    animation: true,
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">Manifest {{manifestNumber}}</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <div class="row">
                                        <div class="vertical-align">
                                            <div class="col-md-6">
                                                <req></req>
                                                <label>Actual Loading Date</label>
                                            </div>
                                            <div class="col-md-6">
                                                <gts-date format="yyyy/MM/dd HH:mm" ng-model="loadingDate" name="loadingDate" show-time="true"></gts-date>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-6">
                                                <req></req>
                                                <label>Arrival Date</label>
                                            </div>
                                            <div class="col-md-6">
                                                <gts-date format="yyyy/MM/dd" ng-model="arrivalDate" name="arrival"></gts-date>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-6">
                                                <req></req>
                                                <label>Actual Destination Warehouse Date</label>
                                            </div>
                                            <div class="col-md-6">
                                                <gts-date format="yyyy/MM/dd" ng-model="dateAtStore" name="warehouse"></gts-date>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()" ng-disabled="!loadingDate || !arrivalDate || !dateAtStore">OK</button>
                                    <button class="btn btn-info" type="button" ng-click="cancel()">Cancel</button>
                                </div>
                            </div>`,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService) => {
                        $scope.manifestNumber = manifest.ManifestNumber;
                        $scope.loadingDate = manifest.LoadingDate;
                        $scope.arrivalDate = manifest.ArrivalDate;
                        $scope.dateAtStore = manifest.DateAtStore;

                        $scope.ok = () => {
                            manifest.LoadingDate = $scope.loadingDate;
                            manifest.ArrivalDate = $scope.arrivalDate;
                            manifest.DateAtStore = $scope.dateAtStore;
                            $uibModalInstance.close(true);
                        };

                        $scope.cancel = () => {
                            $uibModalInstance.dismiss(false);
                        };
                    },
                    resolve: {
                    }
                }).result;
            }

            delivered() {
                var selected = this.apiManifestList.selection.getSelectedRows()[0];

                this.showDeliveredModal(selected).then((data) => {
                    if (data) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'man.list'
                        }, () => {
                            return this.manifestService.updateManifestDelivered(selected.Id).save({}, {
                                dateLoading: selected.LoadingDate,
                                dateArrived: selected.ArrivalDate,
                                dateAtStore: selected.DateAtStore
                            }, (result) => {
                                this.$anchorScroll("topAnchor");
                                this.generalService.displayMessageHandler(result);

                                if (!result.HasErrorMessage) {
                                    this.DoSearch();
                                }
                                }, (errorResponse) => {
                                    this.$anchorScroll("topAnchor");
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                    }

                }, () => {

                });
            }
            
            showCloseModal(manifest: interfaces.manifest.IManifestDisplay) {
                return this.$uibModal.open({
                    animation: true,
                    size: 'lg',
                    templateUrl: `templates/modules/applicationmain/manifest/closeModalView.html`,
                    controller: manifestUpdateModalCtrl,
                    controllerAs: 'manifestUpdateModalCtrl',
                    resolve: {
                        displayManifest: () => { return manifest; }
                    }
                }).result;
            }

            close() {
                var selected = this.apiManifestList.selection.getSelectedRows()[0];

                this.showCloseModal(selected).then((data) => {
                    if (data) {
                        this.DoSearch();
                    }
                }, () => {

                });
            }

            showCancelModal(manifest: interfaces.manifest.IManifestDisplay) {
                return this.$uibModal.open({
                    animation: true,
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">Cancel Manifest</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <div class="row">
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt></opt>
                                                <label>Manifest Number</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{manifestNumber}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt></opt>
                                                <label>Manifest Date</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{manifestDate | momentDateFilter: 'YYYY/MM/DD HH:mm'}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt></opt>
                                                <label>Version</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{version}}
                                                </p>
                                            </div>
                                        </div>                                        
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt></opt>
                                                <label>Alternate Code</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{alternateReference}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt></opt>
                                                <label>Route</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{route}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt></opt>
                                                <label>Transport Mode</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    {{transportMode}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <opt ng-if="!EnforceVersionCancelReason"></opt>
                                                <req ng-if="EnforceVersionCancelReason"></req>
                                                <label>Cancellation Reason</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                <gts-dropdown ng-model="versionCancelReason" load-data="getVersionCancelReasons()"></gts-dropdown>
                                                </p>
                                            </div>
                                        </div>                                        
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <req></req>
                                                <label>Reason</label>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-12">
                                                <textarea rows="3" name="reason" ng-model="reason" class="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()" ng-disabled="!reason || (EnforceVersionCancelReason && !versionCancelReason)">OK</button>
                                    <button class="btn btn-info" type="button" ng-click="cancel()">Cancel</button>
                                </div>
                            </div>`,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, manifestTypeService: interfaces.manifest.IManifestTypeService) => {
                        $scope.manifestNumber = manifest.ManifestNumber;
                        $scope.manifestDate = manifest.ManifestDate;
                        $scope.alternateReference = manifest.AlternateReference;
                        $scope.route = manifest.Route;
                        $scope.transportMode = manifest.TransportMode;
                        $scope.version = manifest.Version;
                        $scope.entityId = manifest.EntityId;
                       
                        $scope.loadManifestTypeDefaults = () => {
                                this.manifestTypeService.getManifestTypeDefaults(manifest.ManifestTypeId).get().$promise.then((data) => {
                                    $scope.EnforceVersionCancelReason = data.EnforceVersionCancelReason;
                                }, (failureData) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                                });
                         }

                         $scope.loadManifestTypeDefaults();

                         $scope.getVersionCancelReasons = () => {
                            return this.manifestService.getVersionCancelReasons( $scope.entityId ).query((resultList) => {
                    
                            }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            }).$promise;
                         }


                        $scope.ok = () => {
                            var changeReason : interfaces.manifest.IManifestChangeCancel = {
                                reason : $scope.reason,
                                changeReasonId: $scope.versionCancelReason.Id
                            }
                            $uibModalInstance.close(changeReason);
                        };

                        $scope.cancel = () => {
                            $uibModalInstance.dismiss(false);
                        };
                    },
                    resolve: {
                    }
                }).result;
            }

            canceled() {
                var selected = this.apiManifestList.selection.getSelectedRows()[0];

                this.showCancelModal(selected).then((data:interfaces.manifest.IManifestChangeCancel) => {
                    if (data) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'man.list'
                        }, () => {
                            return this.manifestService.cancelManifest(selected.Id, data.reason,data.changeReasonId).save((result) => {
                                this.$anchorScroll("topAnchor");
                                this.generalService.displayMessageHandler(result);

                                if (!result.HasErrorMessage) {
                                    this.DoSearch();
                                }
                            }, (errorResponse) => {
                                this.$anchorScroll("topAnchor");
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                    }
                }, () => {

                });
            }

            validateAllEDI() {
                var selected = this.apiManifestList.selection.getSelectedRows()[0];

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'man.list'
                }, () => {
                    return this.manifestService.eDIValidateForManifest(selected.Id).save((result) => {
                        this.generalService.displayMessageHandler(result);
                        this.$anchorScroll("topAnchor");
                    }, (errorResponse) => {
                        this.$anchorScroll("topAnchor");
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                });
            }

            sendAllEDI() {
                var selected = this.apiManifestList.selection.getSelectedRows()[0];

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'man.list'
                }, () => {
                    return this.manifestService.eDISendForManifest(selected.Id).save((result) => {
                        this.generalService.displayMessageHandler(result);
                        this.$anchorScroll("topAnchor");
                    }, (errorResponse) => {
                        this.$anchorScroll("topAnchor");
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                });
            }

            externalClearing() {
                var selected = this.apiManifestList.selection.getSelectedRows()[0];

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'man.list'
                }, () => {
                    return this.manifestService.sendforExternalClearing(selected.Id).save((result) => {
                        this.generalService.displayMessageHandler(result);
                        this.$anchorScroll("topAnchor");
                    }, (errorResponse) => {
                        this.$anchorScroll("topAnchor");
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                });
            }

            validateGoodsDecGenerate() {
                var selected = this.apiManifestList.selection.getSelectedRows()[0];

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'man.list'
                }, () => {
                    return this.manifestService.getGoodsDeclarationForGenerateManifestResult(selected.Id, false).get((result) => {

                        if (result.value) {
                            this.$anchorScroll("topAnchor");
                            this.generalService.displayMessage("Cannot generate Goods Declaration(s), one or more Goods Declarations are still awaiting response from Customs", Enum.EnumMessageType.Error);
                        } else {
                            this.$timeout(() => {
                                this.doGoodsDecVOC(selected);
                            });
                        }

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                });
            }

            doGoodsDecVOC(selected: interfaces.manifest.IManifestDisplay) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'man.list'
                }, () => {
                    return this.manifestService.getGoodsDeclarationForGenerateManifestResult(selected.Id, true).get((result) => {

                        if (result.value) {
                            this.showGenerateVOCModal(selected);
                        } else {
                            this.$timeout(() => {
                                this.generateGoodsDec(selected);
                            });
                        }

                    }, (errorResponse) => {
                        this.$anchorScroll("topAnchor");
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                });
            }

            showGenerateVOCModal(selected: interfaces.manifest.IManifestDisplay) {
                return this.$uibModal.open({
                    animation: true,
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">Generate Goods Declaration</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <div class="row">
                                        <div class="vertical-align">
                                            <div class="col-md-4">
                                                <req></req>
                                                <label>Reason</label>
                                            </div>
                                        </div>
                                        <div class="vertical-align">
                                            <div class="col-md-12">
                                                <textarea rows="3" name="reason" ng-model="reason" class="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok(385)" ng-disabled="!reason">Amendment</button>
                                    <button class="btn btn-primary" type="button" ng-click="ok(386)" ng-disabled="!reason">Cancellation</button>
                                    <button class="btn btn-info" type="button" ng-click="cancel()">Cancel</button>
                                </div>
                            </div>`,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService) => {
                        $scope.ok = (functionClassValue: number) => {
                            $uibModalInstance.close({ reason: $scope.reason, functionClassValue: functionClassValue });
                        };

                        $scope.cancel = () => {
                            $uibModalInstance.dismiss(false);
                        };
                    },
                    resolve: {
                    }
                }).result.then((result) => {
                    this.generateGoodsDec(selected, result.functionClassValue, result.reason);
                }, () => {

                });
            }

            generateGoodsDec(selected: interfaces.manifest.IManifestDisplay, functionClassValue?: number, reason?: string) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'man.list'
                }, () => {
                    return this.manifestService.ediGenAllGoodsDecForManifest(selected.Id, functionClassValue, reason).save((result) => {
                        this.$anchorScroll("topAnchor");
                        this.generalService.displayMessageHandler(result);

                    }, (errorResponse) => {
                        this.$anchorScroll("topAnchor");
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                });
            }

            createActions() {
                this.requestClearingAction = {
                    Name: "Request Clearing",
                    callBack: () => { this.requestClearing() }
                };
                this.cIAcknowledgedAction = {
                    Name: "CI Acknowledged",
                    callBack: () => { this.cIAcknowledged() }
                };
                this.readyForDispatchAction = {
                    Name: "Ready for Dispatch",
                    callBack: () => { this.readyForDispatch() }
                };
                this.dispatchAction = {
                    Name: "Dispatch",
                    callBack: () => { this.dispatch() }
                };
                this.arrivedAction = {
                    Name: "Arrived",
                    callBack: () => { this.arrived() }
                };
                this.deliveredAction = {
                    Name: "Delivered",
                    callBack: () => { this.delivered() }
                };
                this.closedAction = {
                    Name: "Closed",
                    callBack: () => { this.close() }
                };
                this.cancelAction = {
                    Name: "Cancel",
                    callBack: () => { this.canceled() }
                };

                this.generateAllGoodsDeclarationActions = {
                    Name: "Regenerate All Goods Declerations",
                    callBack: () => { this.validateGoodsDecGenerate() }
                };

                this.validateAllEDIAction = {
                    Name: "Validate All for EDI",
                    callBack: () => { this.validateAllEDI() }
                };

                this.sendAllEDIAction = {
                    Name: "Send All for EDI",
                    callBack: () => { this.sendAllEDI() }
                };

                this.externalClearingAction = {
                    Name: "Send for External Clearing",
                    callBack: () => { this.externalClearing() }
                };
            }

            loadActions(statusClassificationValueId: Enum.EnumManifestStatus) {
                this.actionsList = [];

                switch (statusClassificationValueId)
                {
                    case Enum.EnumManifestStatus.Arrived:
                        this.actionsList.push(this.deliveredAction);
                        this.actionsList.push(this.closedAction);
                        this.actionsList.push(this.cancelAction);
                        break;
                    case Enum.EnumManifestStatus.CIAcknowledged:

                        if (this.actionRightsList.some((value) => { return value === EnumManifestRights.ReadyForDispatch })) {
                            this.actionsList.push(this.readyForDispatchAction);
                        }

                        break;
                    case Enum.EnumManifestStatus.Delivered:
                        this.actionsList.push(this.closedAction);
                        this.actionsList.push(this.cancelAction);
                        break;
                    case Enum.EnumManifestStatus.Dispatch:
                    case Enum.EnumManifestStatus.GateIn:
                    case Enum.EnumManifestStatus.GateOut:
                        this.actionsList.push(this.arrivedAction);
                        this.actionsList.push(this.deliveredAction);
                        this.actionsList.push(this.closedAction);
                        this.actionsList.push(this.cancelAction);
                        break;
                    case Enum.EnumManifestStatus.Planned:
                        if (this.actionRightsList.some((value) => { return value === EnumManifestRights.RequestClearing })) {
                            this.actionsList.push(this.requestClearingAction);
                        }

                        if (this.actionRightsList.some((value) => { return value === EnumManifestRights.Dispatch })) {
                            this.actionsList.push(this.dispatchAction);
                        }

                        this.actionsList.push(this.closedAction);
                        this.actionsList.push(this.cancelAction);
                        break;
                    case Enum.EnumManifestStatus.ReadyforDispatch:

                        if (this.actionRightsList.some((value) => { return value === EnumManifestRights.Dispatch })) {
                            this.actionsList.push(this.dispatchAction);
                        }

                        break;
                    case Enum.EnumManifestStatus.RejectedByCustoms:
                        if (this.actionRightsList.some((value) => { return value === EnumManifestRights.RequestClearing })) {
                            this.actionsList.push(this.requestClearingAction);
                        }
                        this.actionsList.push(this.closedAction);
                        this.actionsList.push(this.cancelAction);
                        break;
                    case Enum.EnumManifestStatus.ReleasedByCustoms:

                        if (this.actionRightsList.some((value) => { return value === EnumManifestRights.Dispatch })) {
                            this.actionsList.push(this.dispatchAction);
                        }

                        this.actionsList.push(this.arrivedAction);
                        this.actionsList.push(this.deliveredAction);
                        this.actionsList.push(this.closedAction);
                        this.actionsList.push(this.cancelAction);
                        break;
                    case Enum.EnumManifestStatus.RequestClearing:
                        this.actionsList.push(this.cIAcknowledgedAction);

                        if (this.actionRightsList.some((value) => { return value === EnumManifestRights.ReadyForDispatch })) {
                            this.actionsList.push(this.readyForDispatchAction);
                        }

                        break;

                }

                if (this.actionRightsList.some((value) => { return value === EnumManifestRights.GenerateAllGoodsDeclarations })) {
                    this.actionsList.push(this.generateAllGoodsDeclarationActions);
                }

                if (this.actionRightsList.some((value) => { return value === EnumManifestRights.ValidateAllEDI })) {
                    this.actionsList.push(this.validateAllEDIAction);
                }

                if (this.actionRightsList.some((value) => { return value === EnumManifestRights.RequestClearing })) {
                    this.actionsList.push(this.externalClearingAction);
                }

                if (this.actionRightsList.some((value) => { return value === EnumManifestRights.SendEDI })) {
                    this.actionsList.push(this.sendAllEDIAction);
                }
            }
        };

        export class manifestUpdateModalCtrl {
            manifestNumber: string;
            manifest: interfaces.manifest.IManifestClose;
            showAll: boolean = false;

            constructor(private $scope: IManifestScope, private manifestService: interfaces.manifest.IManifestService,
                private $rootScope: interfaces.applicationcore.IRootScope, public generalService: interfaces.applicationcore.IGeneralService, private $q: ng.IQService,
                private entityService: interfaces.applicationcore.IEntityService, private $state: ng.ui.IStateService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private $timeout: ng.ITimeoutService,
                private $transitions: ng.ui.core.ITransition,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,
                private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                private displayManifest: interfaces.manifest.IManifestDisplay,
                private documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                private uiGridConstants: uiGrid.IUiGridConstants) {

                this.manifestNumber = displayManifest.ManifestNumber;

                this.loadManifest();
            }

            loadManifest() {
                this.bsLoadingOverlayService.wrap(
                    {
                        referenceId: 'update.modal'
                    },
                    () => {
                        return this.manifestService.getManifestCloseDetails(this.displayManifest.Id).get((result) => {
                            this.manifest = result;
                            this.gvwConsignments.data = this.manifest.ConsignmentList;

                            this.$timeout(() => {
                                if (this.apiConsignments) {
                                    this.apiConsignments.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
                                }
                            });
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data, this.$scope);
                            }).$promise;
                    });
            }

            getDocuments(showAll:boolean) {
                return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: this.manifest.ManifestNumber, systemType: Enum.EnumSystemType.Manifest, ownerEntityId: this.manifest.EntityId,showall:showAll }, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                    this.manifest.DocumentRepository = documentRepository;

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            refreshDocuments(showAll:boolean) {
                this.showAll = showAll;
                this.manifest.DocumentRepository.DocumentRepositoryFiles = null;
                return this.getDocuments(showAll);
            }
            
            showManifestHistory_click() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'update'
                }, () => {
                    return this.manifestService.getStatusHistory(this.manifest.ID).query((result) => {
                        this.showManifestHistory(result);
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                });
            }

            showManifestHistory(statusList: interfaces.manifest.IManifestStatusHistory[]) {
                var modalInstance = this.$uibModal.open({
                    animation: true,
                    templateUrl: 'templates/modules/applicationmain/master/statusControlViewHistory.html',
                    controller: ['$uibModalInstance', 'TransactionStatusHistoryList', '$scope', ($modalInstance, TransactionStatusHistoryList, $scope) => {
                        $scope.TransactionStatusHistoryList = TransactionStatusHistoryList;
                    }],
                    size: "lg",
                    resolve: {
                        TransactionStatusHistoryList: () => { return statusList }
                    }
                });

                modalInstance.result.then(() => { }, () => { })
            }

            apiConsignments: uiGrid.IGridApi;
            gvwConsignments: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                showGridFooter: true,
                onRegisterApi: (gridApi) => {
                    this.apiConsignments = gridApi;
                },
                columnDefs: [{
                    name: "ENTITY",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 75
                }, {
                    name: "CONSIGNMENT",
                    displayName: "Consignment",
                    field: "ConsignmentNumber",
                    type: "string",
                    width: 250
                }, {
                    name: "Consignee",
                    displayName: "Consignee",
                    field: "Consignee",
                    type: "number",
                    width: 250
                }, {
                    name: "StoreDate",
                    displayName: "Store Delivery Date",
                    field: "ActualDateAtStore",
                    width: 150,
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                    editableCellTemplate: `
                            <form name="inputForm">
                                    <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-expdate"></gts-date>
                            </form>`
                }, {
                    name: "UploadPOD",
                    displayName: "",
                    field: "",
                    width: 100,
                    cellTemplate: `<div class="ui-grid-cell-contents"><a style="cursor: pointer;" ng-click="grid.appScope.manifestUpdateModalCtrl.openRepositoryFile(row.entity)">Upload POD</a></div>`,
                }]
            };

            openRepositoryFile = (consignment: interfaces.manifest.IManifestCloseConsignment) => {

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'update.modal'
                },
                    () => {
                        return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: consignment.ConsignmentNumber, systemType: Enum.EnumSystemType.Consignment, ownerEntityId: consignment.OwnerEntityId,showall:true }, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                            this.$uibModal.open({
                                animation: true,
                                templateUrl: 'templates/modules/documentManagement/documentRepositoryFileEditView.html',
                                controller: 'documentRepositoryFileEditCtrl',
                                controllerAs: 'docRepCtrl',
                                size: "md",
                                resolve: {
                                    items: function () {
                                        return {
                                            DocRep: documentRepository,
                                            DocFile: undefined
                                        };
                                    }
                                }
                            }).result.then((result: boolean) => {
                                if (result) {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'update.modal'
                                    }, () => {
                                        return this.refreshDocuments(this.showAll);
                                    });
                                }
                            });
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
                

                
            }

            Save() {
                this.bsLoadingOverlayService.wrap({
                        referenceId: 'update.modal'
                    },
                    () => {
                        return this.manifestService.updateManifestClose().save((this.manifest), (result: interfaces.applicationcore.IMessageHandler) => {
                            this.generalService.displayMessageHandler(result, this.$scope);

                            if (!result.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.loadManifest();
                                });
                            }
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data, this.$scope);
                        }).$promise;
                    });
            }

            Confirm() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'update.modal'
                },
                    () => {
                        return this.manifestService.closeManifest(this.manifest.ID).save((result: interfaces.applicationcore.IMessageHandler) => {

                            if (!result.HasErrorMessage) {
                                this.$uibModalInstance.close(true);
                                this.generalService.displayMessageHandler(result);
                            } else {
                                this.generalService.displayMessageHandler(result, this.$scope);
                            }
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data, this.$scope);
                        }).$promise;
                    });
            }

            Close() {
                this.$uibModalInstance.close(true);
            }
        };

        angular.module("app").controller("manifestListCtrl", controllers.manifest.manifestListCtrl);
    }
}